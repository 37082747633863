.appBody {
  background-image: url(./media/image/eli_room.jpg);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headPatSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.creditsSection {
  position: fixed;
  bottom: 0;
  margin-bottom: 1rem;
  color: black;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chibi {
  height: 40vh;
  margin-bottom: -1.5rem;
}

.headPatButton {
  display: inline-block;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #f8f3f1;
  background-color: #9e7d96;
  border-color: #9e7d96;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.headPatButton:hover {
  color: #f8f3f1;
  background-color: #a07094;
  border-color: #a07094;
}

.headPatButton:disabled {
  color: #f8f3f1;
  background-color: gray;
  border-color: gray;
}

.textContainer {
  color: #f8f3f1;
  background-color: #bda7b7;
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}